<template>
  <dash-page-new
      :subtitle="$t('Settings')"
      :root="$t('Dashboard')"
      :root-location="businessDashLink('')"

      :title="$store.state.settings.pageData.title || $t('Settings')"
      :icon="$store.state.settings.pageData.icon || 'mdi-cogs'"
      :filters="$store.state.settings.pageData.filters || {}"
      :header-route="$store.state.settings.pageData.headerRoute || null"
      :filters-input="$store.state.settings.filtersData || []"
      :side-navigation="$store.state.settings.navigationSelect"
      @filter="$store.state.settings.filtersData = $event"

      navigation-route="settings"
      v-model="navigation"
  >

    <template #header_action>
      <v-btn v-if="$store.state.settings.pageData.headerAction"
             @click="$store.state.settings.pageData.headerAction()"
             :color="wsATTENTION"
             class="noCaps"
             :block="SM"
             dark>
        {{ $store.state.settings.pageData.headerActionText }}
      </v-btn>
    </template>

    <template #default>
      <router-view></router-view>
    </template>



  </dash-page-new>
</template>

<script>

import {mapState} from "vuex";

export default {
  name: "BusinessSettings",
  data() {
    return {
      navigation : ''
    }
  },
  watch : {
    pageData : {
      handler(value) {
        if ( JSON.parse(JSON.stringify(value.navigationSelect)) !== JSON.parse(JSON.stringify(this.navigationSelect)) ) {
          this.$store.state.settings.navigationSelect = JSON.parse(JSON.stringify(value.navigationSelect))
        }
      },
      deep : true
    }
  },
  computed : {
    ...mapState('settings', ['pageData']),

    navigationItems() {
      let items =  [
        { text : this.$t('Payment')     , auth : ['businessman'], permission:'MODULE_ONLINEPAYMENT',  value : 'payment'   , path : 'payment'   , icon : "mdi-credit-card" },
        { text : this.$t('Storage')     , value : 'storage'   , path : 'storage'   , icon : "mdi-server" },
        { text : this.$t('CrmSystem')   , value : 'crm'       ,  permission:'MODULE_LEADS', path : 'crm'       , icon : "mdi-card-account-phone-outline" },
        // { text : this.$t('Delivery')   , value : 'delivery'  , path : 'delivery'  , icon : "mdi-truck-delivery-outline" },
        { text : this.$t('Managers')    ,
          value : 'managers'  ,
          path : 'managers'  ,
          icon : "mdi-account-tie" ,
          permission:'MODULE_MANAGERS',
        },
        // { text : this.$t('Documents')    ,
        //   value : 'documents'  ,
        //   path : 'documents'  ,
        //   icon : "mdi-file-sign" ,
        //   permission:'MODULE_MANAGERS',
        // },
        { text : this.$t('Enrollment')    ,
          value : 'enrollment'  ,
          path : 'enrollment'  ,
          icon : "mdi-account-multiple-plus-outline" ,
          permission:'MODULE_MANAGERS',
        },

        // { text : this.$t('WebSite') ,
        //   value : 'site'   ,
        //   path : 'site'   ,
        //   icon : "mdi-web",
        //   permission:'MODULE_TEMPLATES',
        // },
        // { text : this.$t('Newsletter')     , value : 'newsletter'   , path : 'newsletter'   , icon : "mdi-mail" },
      ]
      if ( this.IS_BUSINESS_OWNER ) {
        items.unshift({
          text : this.$t('General')    ,
          value : 'general'  ,
          path : 'general'  ,
          icon : "mdi-cogs" ,
        },)
      }
      return items
    }
  },
  mounted() {
    this.$store.state.settings.navigationSelect = this.navigationItems
  },
  beforeDestroy() {
    this.$store.state.settings.pageData = {}
    this.$store.state.settings.filtersData = {}
  }
}
</script>

<style scoped>

</style>